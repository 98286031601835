/**
 * @file AboutPage.js
 * @description About page component for the NOT THE S*ME application
 */

// Import necessary dependencies
import React from "react";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin } from "lucide-react";
import notTheSameLogo from ".././images/NOT-THE-SAME_logo_v0.jpg";

/**
 * @function AboutPage
 * @description Renders the about page of the application
 * @returns {JSX.Element} The about page component
 */
const AboutPage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Header */}
      <header className="px-4 lg:px-6 h-20 flex items-center bg-[#7E57C2] shadow-md">
        {/* Logo */}
        <Link className="flex items-center justify-center" to="/">
          <img
            src={notTheSameLogo}
            alt="NOT THE S*ME Logo"
            width={150}
            height={50}
            className="hover:opacity-80 transition-opacity"
          />
        </Link>
        {/* Navigation */}
        <nav className="ml-auto flex gap-4 sm:gap-6">
          {["Home", "Register", "Login"].map((item) => (
            <Link
              key={item}
              className="text-sm font-medium text-white hover:bg-[#6A1B9A] py-2 px-3 rounded transition-colors"
              to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
            >
              {item}
            </Link>
          ))}
        </nav>
      </header>
      {/* Main content */}
      <main className="flex-1 container mx-auto px-4 py-12">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          {/* About Us section */}
          <div className="p-8">
            <h1 className="text-4xl font-bold mb-8 text-center text-[#7E57C2]">
              About Us
            </h1>
            <p className="text-lg mb-8 text-gray-700 leading-relaxed">
              NOT THE S*ME is a fintech startup revolutionizing SME lending in
              Saudi Arabia and UAE. We're committed to providing fast, fair, and
              transparent lending solutions tailored for small and medium
              enterprises.
            </p>
          </div>
          {/* Contact Us section */}
          <div className="bg-gray-50 p-8">
            <h2 className="text-3xl font-bold mb-6 text-[#7E57C2]">
              Contact Us
            </h2>
            <p className="text-lg mb-6 text-gray-700">
              Get in touch with us at{" "}
              <a
                href="mailto:contact@notthesame.com"
                className="text-[#7E57C2] hover:underline font-medium"
              >
                contact@notthesame.com
              </a>
            </p>
          </div>
          {/* Follow Us section */}
          <div className="p-8">
            <h2 className="text-3xl font-bold mb-6 text-[#7E57C2]">
              Follow Us
            </h2>
            <div className="flex justify-center space-x-6">
              {/* Social media links */}
              {[
                {
                  icon: Linkedin,
                  href: "https://www.linkedin.com/company/notthesame",
                },
                {
                  icon: Instagram,
                  href: "https://www.instagram.com/notthesame",
                },
                { icon: Facebook, href: "https://www.facebook.com/notthesame" },
              ].map(({ icon: Icon, href }) => (
                <a
                  key={href}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#7E57C2] hover:text-[#6A1B9A] transform hover:scale-110 transition-transform"
                >
                  <Icon size={32} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </main>
      {/* Footer */}
      <footer className="py-6 text-center text-gray-500 text-sm bg-white shadow-inner">
        © 2023 NOT THE S*ME. All rights reserved.
      </footer>
    </div>
  );
};

export default AboutPage;
