/**
 * @file LandingPage.js
 * @description Landing page component for the NOT THE S*ME application
 */

import React, { useState } from "react";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { MessageCircle, X, Check } from "lucide-react";
import { Link } from "react-router-dom";
import notTheSameLogo from ".././images/NOT-THE-SAME_logo_v0.jpg";

/**
 * @function LandingPage
 * @description Renders the landing page of the application
 * @returns {JSX.Element} The landing page component
 */
const LandingPage = () => {
  // State to control the visibility of the chatbot
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="px-4 lg:px-6 h-20 flex items-center bg-[#7E57C2] shadow-md fixed w-full z-50">
        {/* Logo */}
        <Link className="flex items-center justify-center" to="/">
          <img
            src={notTheSameLogo}
            alt="NOT THE S*ME Logo"
            width={150}
            height={50}
            className="hover:opacity-80 transition-opacity"
          />
        </Link>
        {/* Navigation */}
        <nav className="ml-auto flex gap-4 sm:gap-6">
          {["About", "Register", "Login"].map((item) => (
            <Link
              key={item}
              className="text-sm font-medium text-white hover:bg-[#6A1B9A] py-2 px-3 rounded transition-colors"
              to={`/${item.toLowerCase().replace(/\s+/g, "-")}`}
            >
              {item}
            </Link>
          ))}
        </nav>
      </header>

      {/* Main content */}
      <main className="flex-1 pt-20">
        {/* Hero section */}
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-gradient-to-r from-[#7E57C2] to-[#6A1B9A] text-white">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="flex flex-col items-center space-y-4 text-center">
              <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl">
                Empowering SMEs with Flexible Financing
              </h1>
              <p className="mx-auto max-w-[700px] text-gray-200 md:text-xl">
                Fast, fair, and transparent lending solutions tailored for small
                and medium enterprises.
              </p>
              <div className="space-x-4 mt-8">
                <Button className="bg-white text-[#5d3e94] hover:bg-[#6A1B9A] hover:text-white transform hover:scale-105 transition-all">
                  Apply for a Loan
                </Button>
                <Button className="bg-white text-[#5d3e94] hover:bg-[#6A1B9A] hover:text-white transform hover:scale-105 transition-all">
                  Learn More
                </Button>
              </div>
            </div>
          </div>
        </section>

        {/* Features section */}
        <section className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12 text-[#7E57C2]">
              Why Choose Us?
            </h2>
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
              {/* Feature cards */}
              {[
                {
                  title: "Competitive Rates",
                  icon: "dollar-sign",
                  description:
                    "We offer some of the most competitive interest rates in the market.",
                },
                {
                  title: "Quick Approval",
                  icon: "clock",
                  description:
                    "Get approved in as little as 24 hours with our streamlined process.",
                },
                {
                  title: "Secure & Transparent",
                  icon: "shield",
                  description:
                    "Your data is protected, and our terms are always clear and upfront.",
                },
              ].map(({ title, icon, description }) => (
                <div
                  key={title}
                  className="flex flex-col items-center text-center p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-lg transition-shadow"
                >
                  <div className="rounded-full bg-[#7E57C2] p-3 mb-4">
                    <svg
                      className="h-8 w-8 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d={
                          icon === "dollar-sign"
                            ? "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08-.402 2.599-1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            : icon === "clock"
                            ? "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            : "M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                        }
                      />
                    </svg>
                  </div>
                  <h3 className="text-xl font-bold mb-2 text-[#7E57C2]">
                    {title}
                  </h3>
                  <p className="text-gray-600">{description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Business growth section */}
        <section
          className="w-full py-12 md:py-24 lg:py-32 relative bg-fixed bg-cover bg-top"
          style={{
            backgroundImage:
              "url('https://hebbkx1anhila5yf.public.blob.vercel-storage.com/model-clPXlB72TkPHeKnO55iKuBdsrNQjLl.jpg')",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 container px-4 md:px-6 mx-auto">
            <div className="grid gap-10 lg:grid-cols-2 items-center">
              <div className="space-y-4 bg-white/90 p-6 rounded-lg shadow-xl">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-[#7E57C2]">
                  Grow Your Business with Confidence
                </h2>
                <p className="text-gray-700 md:text-xl">
                  Our tailored lending solutions are designed to help your SME
                  thrive. From working capital to expansion funds, we've got you
                  covered.
                </p>
                <ul className="grid gap-4">
                  {[
                    "Flexible repayment terms",
                    "No hidden fees",
                    "Dedicated account manager",
                  ].map((item) => (
                    <li key={item} className="flex items-center gap-2">
                      <Check className="h-5 w-5 text-[#7E57C2]" />
                      <span className="text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Call-to-action section */}
        <section className="w-full py-12 md:py-24 lg:py-32 bg-[#7E57C2]">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-white">
                Ready to Take Your Business to the Next Level?
              </h2>
              <p className="mx-auto max-w-[600px] text-gray-200 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Apply now and get the funding you need to achieve your business
                goals.
              </p>
              <div className="w-full max-w-sm space-y-2">
                <form className="flex space-x-2">
                  <Input
                    className="max-w-lg flex-1"
                    placeholder="Enter your email"
                    type="email"
                  />
                  <Button
                    type="submit"
                    className="bg-white text-[#5d3e94] hover:bg-[#6A1B9A] hover:text-white transform hover:scale-105 transition-all"
                  >
                    Apply for a Loan
                  </Button>
                </form>
                <p className="text-xs text-gray-300">
                  By submitting, you agree to our{" "}
                  <Link
                    className="underline underline-offset-2 hover:text-white"
                    to="#"
                  >
                    Terms & Conditions
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="py-6 bg-gray-100">
        <div className="container mx-auto px-4 md:px-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-xs text-gray-500 mb-4 md:mb-0">
              © 2024 NOT THE S*ME. All rights reserved.
            </p>
            <nav className="flex gap-4 sm:gap-6">
              <Link
                className="text-xs hover:underline underline-offset-4 text-gray-500 hover:text-gray-700"
                to="/terms"
              >
                Terms of Service
              </Link>
              <Link
                className="text-xs hover:underline underline-offset-4 text-gray-500 hover:text-gray-700"
                to="/privacy"
              >
                Privacy
              </Link>
            </nav>
          </div>
        </div>
      </footer>

      {/* Chatbot button */}
      <Button
        className="fixed bottom-4 right-4 rounded-full p-4 bg-[#7E57C2] text-white hover:bg-[#6A1B9A] shadow-lg transform hover:scale-110 transition-transform z-50"
        onClick={() => setIsChatbotOpen(true)}
      >
        <MessageCircle className="h-6 w-6" />
        <span className="sr-only">Open Chatbot</span>
      </Button>

      {/* Chatbot modal */}
      {isChatbotOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-bold text-[#7E57C2]">Chat with Us</h2>
              <Button variant="ghost" onClick={() => setIsChatbotOpen(false)}>
                <X className="h-6 w-6" />
                <span className="sr-only">Close</span>
              </Button>
            </div>
            <div className="h-96 overflow-y-auto p-4">
              <p className="text-gray-500">
                Welcome to NOT THE S*ME. How can we help you today?
              </p>
            </div>
            <div className="p-4 border-t">
              <form className="flex space-x-2">
                <Input className="flex-1" placeholder="Type your message..." />
                <Button
                  type="submit"
                  className="bg-[#7E57C2] text-white hover:bg-[#6A1B9A]"
                >
                  Send
                </Button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
