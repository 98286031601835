/**
 * @fileoverview Input component for the UI
 */

// Import React library
import React from "react";

/**
 * Input component
 * @param {Object} props - The properties passed to the component
 * @param {string} [props.className] - Additional CSS classes to be applied to the input
 * @returns {React.ReactElement} An input element with the specified properties
 */
export const Input = ({ className, ...props }) => (
  <input
    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${className}`}
    {...props}
  />
);
