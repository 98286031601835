/**
 * @file RegisterForm.js
 * @description Component for user registration form
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Link } from "react-router-dom";
import notTheSameLogo from ".././images/NOT-THE-SAME_logo_v0.jpg";

/**
 * @constant {string} API_URL - The base URL for API requests
 */
const API_URL =
  process.env.REACT_APP_API_URL || "https://www.notthesme.com:5000";

/**
 * @function RegisterForm
 * @description Component for rendering and handling the registration form
 * @returns {JSX.Element} The rendered registration form
 */
const RegisterForm = () => {
  /**
   * @constant {Object} formData - State for storing form input values
   * @constant {function} setFormData - Function to update formData state
   */
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    companyName: "",
    annualRevenue: "",
    employeeCount: "",
  });

  /**
   * @constant {Object} errors - State for storing form validation errors
   * @constant {function} setErrors - Function to update errors state
   */
  const [errors, setErrors] = useState({});

  /**
   * @constant {function} navigate - Hook for programmatic navigation
   */
  const navigate = useNavigate();

  /**
   * @function validateForm
   * @description Validates the form inputs
   * @returns {boolean} Whether the form is valid or not
   */
  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    // Email validation
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    // Password validation
    if (!formData.password || formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
      isValid = false;
    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(formData.password)) {
      newErrors.password =
        "Password must contain at least one uppercase letter, one lowercase letter, and one number";
      isValid = false;
    }

    // Company name validation
    if (!formData.companyName || formData.companyName.length < 2) {
      newErrors.companyName = "Company name must be at least 2 characters long";
      isValid = false;
    }

    // Annual revenue validation
    if (
      !formData.annualRevenue ||
      formData.annualRevenue < 5000000 ||
      formData.annualRevenue > 50000000
    ) {
      newErrors.annualRevenue =
        "Annual revenue must be between 5,000,000 and 50,000,000 SAR";
      isValid = false;
    }

    // Employee count validation
    if (
      !formData.employeeCount ||
      formData.employeeCount < 10 ||
      formData.employeeCount > 50
    ) {
      newErrors.employeeCount = "Employee count must be between 10 and 50";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  /**
   * @function handleChange
   * @description Handles changes in form inputs
   * @param {Object} e - The event object
   */
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * @function handleSubmit
   * @description Handles form submission
   * @param {Object} e - The event object
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch(`${API_URL}/api/users/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const data = await response.json();
        if (response.ok) {
          localStorage.setItem("token", data.token);
          navigate("/dashboard");
        } else {
          setErrors({ general: data.message });
        }
      } catch (err) {
        setErrors({ general: "An error occurred. Please try again." });
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="px-4 lg:px-6 h-20 flex items-center bg-[#7E57C2]">
        <Link className="flex items-center justify-center" to="/">
          <img
            src={notTheSameLogo}
            alt="NOT THE S*ME Logo"
            width={150}
            height={50}
          />
        </Link>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <Link
            className="text-sm font-medium text-white hover:underline underline-offset-4"
            to="/"
          >
            Home
          </Link>
          <Link
            className="text-sm font-medium text-white hover:underline underline-offset-4"
            to="/register"
          >
            Register
          </Link>
          <Link
            className="text-sm font-medium text-white hover:underline underline-offset-4"
            to="/login"
          >
            Login
          </Link>
        </nav>
      </header>
      <main className="flex-grow">
        <form
          onSubmit={handleSubmit}
          className="bg-white p-8 rounded shadow-md max-w-md mx-auto"
        >
          <h2 className="text-2xl font-bold mb-4">Register Your Company</h2>
          {errors.general && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
              role="alert"
            >
              {errors.general}
            </div>
          )}
          <div className="mb-4">
            <label className="block mb-2">Email</label>
            <Input type="email" name="email" required onChange={handleChange} />
            {errors.email && (
              <p className="text-red-500 text-xs italic">{errors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-2">Password</label>
            <Input
              type="password"
              name="password"
              required
              onChange={handleChange}
            />
            {errors.password && (
              <p className="text-red-500 text-xs italic">{errors.password}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-2">Company Name</label>
            <Input
              type="text"
              name="companyName"
              required
              onChange={handleChange}
            />
            {errors.companyName && (
              <p className="text-red-500 text-xs italic">
                {errors.companyName}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-2">Annual Revenue (SAR)</label>
            <Input
              type="number"
              name="annualRevenue"
              required
              onChange={handleChange}
            />
            {errors.annualRevenue && (
              <p className="text-red-500 text-xs italic">
                {errors.annualRevenue}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-2">Number of Employees</label>
            <Input
              type="number"
              name="employeeCount"
              required
              onChange={handleChange}
            />
            {errors.employeeCount && (
              <p className="text-red-500 text-xs italic">
                {errors.employeeCount}
              </p>
            )}
          </div>
          <Button type="submit" className="w-full">
            Register
          </Button>
        </form>
      </main>
    </div>
  );
};

export default RegisterForm;
