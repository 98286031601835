/**
 * @file App.js
 * @description Main component for the NOT THE S*ME application
 */

// Import necessary dependencies
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import LandingPage from "./routes/LandingPage";
import LoginForm from "./routes/LoginForm";
import RegisterForm from "./routes/RegisterForm";
import AboutPage from "./routes/AboutPage";
import "./index.css";

/**
 * @function App
 * @description Main application component
 * @returns {JSX.Element} The main application component
 */
const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="/register" element={<RegisterForm />} />
      <Route path="/about" element={<AboutPage />} />
    </Routes>
  </Router>
);

export default App;
