/**
 * @file LandingPage.js
 * @description Landing page component for the NOT THE S*ME application
 */

import React, { useState } from "react";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import notTheSameLogo from ".././images/NOT-THE-SAME_logo_v0.jpg";

/**
 * @function LoginForm
 * @description Component for rendering the login form
 * @returns {JSX.Element} The login form component
 */
const LoginForm = () => {
  // State for email and password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  /**
   * @function handleSubmit
   * @description Handles form submission for login
   * @param {Object} req - The request object
   * @param {Object} res - The response object
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Log login attempt
    console.log("Login submitted", { email, password });

    try {
      // Attempt to login
      const response = await fetch(`${API_URL}/api/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      // TODO: Handle response
      if (response.ok) {
        // If login is successful, navigate to dashboard or home page
        navigate("/dashboard");
      } else {
        // throw error
        throw new Error("Login failed");
      }
    } catch (error) {
      // Log and respond to error
      console.error("Login error:", error);
      res.status(500).json({ message: "Server error during login" });
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="px-4 lg:px-6 h-20 flex items-center bg-[#7E57C2]">
        <Link className="flex items-center justify-center" to="/">
          <img
            src={notTheSameLogo}
            alt="NOT THE S*ME Logo"
            width={150}
            height={50}
          />
        </Link>
        {/* Navigation */}
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <Link
            className="text-sm font-medium text-white hover:underline underline-offset-4"
            to="/"
          >
            Home
          </Link>
          <Link
            className="text-sm font-medium text-white hover:underline underline-offset-4"
            to="/register"
          >
            Register
          </Link>
          <Link
            className="text-sm font-medium text-white hover:underline underline-offset-4"
            to="/login"
          >
            Login
          </Link>
        </nav>
      </header>
      {/* Main content */}
      <main className="flex-grow">
        <form
          onSubmit={handleSubmit}
          className="bg-white p-8 rounded shadow-md max-w-md mx-auto"
        >
          <h2 className="text-2xl font-bold mb-4">Login</h2>
          {/* Email input */}
          <div className="mb-4">
            <label className="block mb-2">Email</label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {/* Password input */}
          <div className="mb-4">
            <label className="block mb-2">Password</label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {/* Submit button */}
          <Button type="submit" className="w-full mb-4">
            Login
          </Button>
          {/* Registration link */}
          <div className="text-center">
            <p className="mb-2">Don't have an account?</p>
            <Button
              type="button"
              onClick={() => navigate("/register")}
              className="bg-green-500 hover:bg-green-600"
            >
              Register New Company
            </Button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default LoginForm;
