/**
 * @fileoverview Button component for the UI
 */

// Import React library
import React from "react";

/**
 * Button component
 * @param {Object} props - The properties passed to the component
 * @param {React.ReactNode} props.children - The content to be rendered inside the button
 * @param {string} [props.className] - Additional CSS classes to be applied to the button
 * @returns {React.ReactElement} A button element with the specified properties
 */
export const Button = ({ children, className, ...props }) => (
  <button
    className={`bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded ${className}`}
    {...props}
  >
    {children}
  </button>
);
